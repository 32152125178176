<template>
	<div class="page-container">
		<div class="header" style="padding-bottom: 0">
			<page-header @navBack="$router.go(-1)" :pageTitle="$route.meta.title">
<!--				<template v-slot:default>-->
<!--					<van-icon size="20" name="question-o"/>-->
<!--				</template>-->
			</page-header>
		</div>
		<div class="content" v-html="content"></div>
	</div>
</template>

<script>
import { getPrivacyPolicy } from '@/api/user'
import PageHeader from "../components/page-header.vue"
export default {
	name: "PrivacyPolicy",
	components: { PageHeader },
	data: () => ({
		content: ''
	}),
	computed: {

	},
	created () {
		getPrivacyPolicy().then(res => {
			this.content = res.content
		})
	}
}
</script>
<style>
	.content {
		padding: 20px;
	}
	.content p {
		margin: 0 0 20px;
		padding: 0;
		font-size: 14px;
		text-align: justify;
		text-indent: 28px;
	}
</style>
